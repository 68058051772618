export default {
    data(){
        return{
            hasData: false
        }
    },
    methods: {
        tableHasData(name){
            if(name.length != 0){
                this.hasData = true
            }else{
                this.hasData = false
            }
        }
    },
    created(){
        
    }
}