<template>
  <vs-sidebar
    data-tour-step="4"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ $t("advancedSearch") }}</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component
      :is="scrollbarTag"
      class="scroll-area--data-list-add-new"
      :settings="settings"
      :key="$vs.rtl"
    >
      <div class="p-6" v-if="!advancedSearchTable">
        <vs-input
          :label="$t('orderCode')"
          v-model="advancedSearchObj.code"
          class="mb-5 w-full font-bold"
          type="text"
          maxlength="20"
          name="code"
          placeholder="مثال : 123456"
        />
        <vs-input
          :label="$t('barcode')"
          v-model="advancedSearchObj.barcode"
          class="mb-5 w-full font-bold"
          type="number"
          maxlength="50"
          name="barcode"
          placeholder="مثال : 35981235469"
        />
        <vs-input
          :label="$t('sender')"
          v-model="advancedSearchObj.sender"
          class="mb-5 w-full font-bold"
          type="text"
          maxlength="50"
          name="sender"
          placeholder="مثال : احمد محمود"
        />
        <vs-input
          :label="$t('invoiceNumber')"
          v-model="advancedSearchObj.invoiceNumber"
          class="mb-5 w-full font-bold"
          type="number"
          maxlength="50"
          name="invoiceNumber"
          placeholder="مثال : 13"
        />
        <vs-input
          :label="$t('receiver')"
          v-model="advancedSearchObj.receiver"
          class="mb-5 w-full font-bold"
          type="text"
          maxlength="50"
          name="receiver"
          placeholder="مثال : احمد محمود"
        />
        <div class="mb-5">
          <label class="input-label font-bold">
            {{ $t("receiverNumber") }} :</label
          >
          <vue-tel-input
            v-model="advancedSearchObj.receiverNumber"
            :inputOptions="internationalInputOptions"
            :validCharactersOnly="true"
            mode="national"
            :autoFormat="false"
            @input="phoneObject"
            name="number"
          ></vue-tel-input>
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{
            errors.first("number")
          }}</span>
        </div>
        <div class="mt-5 mb-5">
          <label class="input-label font-bold" ref="govLabel">المحافظة</label>
          <SelectInputVue
            :options="resultGovs"
            v-model="advancedSearchObj.govName"
          >
            <template #search>
              <input
                @blur.stop
                class="inner-input"
                v-model="search"
                @input="searchAll(1)"
                placeholder="إبحث هنا"
              />
            </template>
          </SelectInputVue>
        </div>
        <vs-input
          :label="$t('area')"
          v-model="advancedSearchObj.area"
          class="mb-5 w-full font-bold"
          type="text"
          maxlength="50"
          name="area"
          placeholder="مثال : السيدة زينب"
        />

        <div v-if="$acl.not.check('isAdminCompany')">
          <div class="input-select mb-5">
            <label class="label-select font-bold" ref="captainLabel">{{
              $t("captain")
            }}</label>
            <input
              readonly
              v-model="advancedSearchObj.captainName"
              :placeholder="$t('captain')"
              @click="openOptions('captainOptions')"
            />
            <div class="select-options" ref="captainOptions">
              <ul>
                <input
                  class="inner-input"
                  v-model="searchCaptain"
                  @input="searchAll(2)"
                  placeholder="إبحث هنا"
                />
                <div
                  class="search-loading"
                  v-if="loading && searchCaptain != ''"
                >
                  <SpinnerColor />
                </div>
                <div v-else>
                  <li
                    v-for="(item, index) in captains"
                    :key="index"
                    @click="
                      resultOptions('captainLabel', 'captainOptions'),
                        (advancedSearchObj.captainName = item.name),
                        (advancedSearchObj.captainId = item.id)
                    "
                  >
                    {{ item.name }}
                  </li>
                </div>
              </ul>
            </div>
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.28516 1.7998L4.77087 5.31409C4.74522 5.34142 4.71424 5.36321 4.67984 5.3781C4.64544 5.39299 4.60835 5.40067 4.57087 5.40067C4.53339 5.40067 4.4963 5.39299 4.4619 5.3781C4.4275 5.36321 4.39652 5.34142 4.37087 5.31409L0.856584 1.7998"
                stroke="black"
                stroke-width="1.71429"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div class="input-select">
            <label class="label-select font-bold" ref="branchLabel">{{
              $t("branch")
            }}</label>
            <input
              readonly
              v-model="advancedSearchObj.branchName"
              :placeholder="$t('branch')"
              @click="openOptions('branchOptions')"
            />
            <div class="select-options" ref="branchOptions">
              <ul>
                <input
                  class="inner-input"
                  v-model="searchBranch"
                  @input="searchAll(3)"
                  placeholder="إبحث هنا"
                />
                <div
                  class="search-loading"
                  v-if="loading && searchBranch != ''"
                >
                  <SpinnerColor />
                </div>
                <div v-else>
                  <li
                    v-for="(item, index) in branches"
                    :key="index"
                    @click="
                      resultOptions('branchLabel', 'branchOptions'),
                        (advancedSearchObj.branchName = item.name),
                        (advancedSearchObj.branchId = item.id)
                    "
                  >
                    {{ item.name }}
                  </li>
                </div>
              </ul>
            </div>
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.28516 1.7998L4.77087 5.31409C4.74522 5.34142 4.71424 5.36321 4.67984 5.3781C4.64544 5.39299 4.60835 5.40067 4.57087 5.40067C4.53339 5.40067 4.4963 5.39299 4.4619 5.3781C4.4275 5.36321 4.39652 5.34142 4.37087 5.31409L0.856584 1.7998"
                stroke="black"
                stroke-width="1.71429"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="p-6" v-else>
        <div v-for="(advancedSearch, index) in advancedSearchData" :key="index">
          <div v-if="advancedSearch.type == 'text'">
            <vs-input
              :label="$t(advancedSearch.label)"
              v-model="advancedSearchObj[advancedSearch.nameToEndpoint]"
              class="mb-5 w-full"
              type="text"
              :maxlenght="advancedSearch.max"
              :name="advancedSearch.label"
            />
          </div>
          <div v-else class="advanced-date">
            <label for="">{{ $t(advancedSearch.label) }} :</label>
            <datepicker
              v-model="advancedSearchObj[advancedSearch.nameToEndpoint]"
              format="dd-MM-yyyy"
            ></datepicker>
          </div>
        </div>
        <div class="mb-5">
          <label class="input-label font-bold" ref="govLabel">المحافظة</label>
          <SelectInputVue
            :options="resultGovs"
            v-model="advancedSearchObj.govName"
          >
            <template #search>
              <input
                @blur.stop
                class="inner-input"
                v-model="search"
                @input="searchAll(1)"
                placeholder="إبحث هنا"
              />
            </template>
          </SelectInputVue>
        </div>

        <div v-if="$acl.not.check('isAdminCompany')">
          <div
            class="input-select mb-5"
            v-if="showCaptian"
          >
            <label class="label-select font-bold" ref="captainLabel">{{
              $t("captain")
            }}</label>
            <input
              readonly
              v-model="advancedSearchObj.captainName"
              :placeholder="$t('captain')"
              @click="openOptions('captainOptions')"
            />
            <div class="select-options" ref="captainOptions">
              <ul>
                <input
                  class="inner-input"
                  v-model="searchCaptain"
                  @input="searchAll(2)"
                  placeholder="إبحث هنا"
                />
                <div
                  class="search-loading"
                  v-if="loading && searchCaptain != ''"
                >
                  <SpinnerColor />
                </div>
                <div v-else>
                  <li
                    v-for="(item, index) in resultCaptians"
                    :key="index"
                    @click="
                      resultOptions('captainLabel', 'captainOptions'),
                        (advancedSearchObj.captainName = item.name),
                        (advancedSearchObj.captainId = item.id)
                    "
                  >
                    {{ item.name }}
                  </li>
                </div>
              </ul>
            </div>
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.28516 1.7998L4.77087 5.31409C4.74522 5.34142 4.71424 5.36321 4.67984 5.3781C4.64544 5.39299 4.60835 5.40067 4.57087 5.40067C4.53339 5.40067 4.4963 5.39299 4.4619 5.3781C4.4275 5.36321 4.39652 5.34142 4.37087 5.31409L0.856584 1.7998"
                stroke="black"
                stroke-width="1.71429"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div
            class="input-select"
            v-if="showBranch"
          >
            <label class="label-select font-bold" ref="branchLabel">{{
              $t("branch")
            }}</label>
            <input
              readonly
              v-model="advancedSearchObj.branchName"
              :placeholder="$t('branch')"
              @click="openOptions('branchOptions')"
            />
            <div class="select-options" ref="branchOptions">
              <ul>
                <input
                  class="inner-input"
                  v-model="searchBranch"
                  @input="searchAll(3)"
                  placeholder="إبحث هنا"
                />
                <div
                  class="search-loading"
                  v-if="loading && searchBranch != ''"
                >
                  <SpinnerColor />
                </div>
                <div v-else>
                  <li
                    v-for="(item, index) in resultBranchs"
                    :key="index"
                    @click="
                      resultOptions('branchLabel', 'branchOptions'),
                        (advancedSearchObj.branchName = item.name),
                        (advancedSearchObj.branchId = item.id)
                    "
                  >
                    {{ item.name }}
                  </li>
                </div>
              </ul>
            </div>
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.28516 1.7998L4.77087 5.31409C4.74522 5.34142 4.71424 5.36321 4.67984 5.3781C4.64544 5.39299 4.60835 5.40067 4.57087 5.40067C4.53339 5.40067 4.4963 5.39299 4.4619 5.3781C4.4275 5.36321 4.39652 5.34142 4.37087 5.31409L0.856584 1.7998"
                stroke="black"
                stroke-width="1.71429"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6 justify-end" slot="footer">
      <button
        class="mr-6 send-order"
        :class="{ 'opacity-btn1': !isFormValid }"
        @click="
          googleEventTrack(
            'الضغط على زر البحث داخل البحث المتقدم',
            'navbar',
            'advanced_search_sidebar'
          ),
            submitData()
        "
        :disabled="!isFormValid"
        style="font-family: Cairo"
      >
        بحث
      </button>
      <button class="cancel-btn" @click="initValues()">اعادة تعيين</button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import allStatus from "@/views/status/orderStatus";
import SpinnerColor from "@/views/components/table-components/SpinnerColor2.vue";
import $ from "jquery";
import landLine from "@/mixins/landLine.js";
import removeSpaceNumber from "@/mixins/removeSpaceNumber.js";
import gTag from "@/mixins/gtag.js";
import validationPhone from "@/mixins/validationPhone.js";
import SelectInputVue from "@/layouts/components/SelectInput.vue";

export default {
  name: "add-advanced-search",
  mixins: [landLine, removeSpaceNumber, gTag, validationPhone],
  props: {
    isSidebarActive: {
      type: Boolean,
    },
    advancedSearchTable: {
      type: Boolean,
      default: false,
    },
    advancedSearchData: {
      type: Array,
    },
    statusForAdvancedSearch: {
      type: Number,
    },
  },
  components: {
    VuePerfectScrollbar,
    Datepicker,
    SpinnerColor,
    SelectInputVue,
  },
  data() {
    return {
      resultGovs: [],
      resultCaptians: [],
      resultBranchs: [],
      search: "",
      searchCaptain: "",
      searchBranch: "",
      allStatusPages: allStatus,
      advancedSearchObj: {
        code: "",
        barcode: "",
        invoiceNumber: "",
        receiverNumber: "",
        govId: "",
        govName: "",
        captainId: "",
        captainName: "",
        branchId: "",
        branchName: "",
        dateOfRegistration: "",
        sender: "",
        receiver: "",
        area: "",
        collectedAmount: "",
        amountCollect: "",
        sentDate: "",
        theDateOfDelivery: "",
        dueDate: "",
        amountSupplied: "",
        dateOfSenderReturn: "",
        dateAddedtoWallet: "",
        returnReason: "",
        status: "",
      },
      pageForBranches: 1,
      pageForCaptains: 1,
      loading: false,

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  async mounted() {
    if(this.showBranch){
      let obj = {
        page: this.pageForBranches,
        search: ""
      }
      await this.getBranches(obj)
      this.resultBranchs = this.branches
    }
    if(this.showCaptian){
      let obj = {
        page: this.pageForCaptains,
        search: ""
      }
      await this.getCaptains(obj)
      this.resultCaptians = this.captains
    }
  },
  computed: {
    ...mapGetters("dataList", ["branches", "captains", "governments"]),
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    showCaptian(){
      if(              
        this.statusForAdvancedSearch != this.allStatusPages.delayed &&
        this.statusForAdvancedSearch != this.allStatusPages.deleted &&
        this.statusForAdvancedSearch != this.allStatusPages.expiredReturn &&
        this.statusForAdvancedSearch != this.allStatusPages.missing &&
        this.statusForAdvancedSearch != this.allStatusPages.saved &&
        this.statusForAdvancedSearch != this.allStatusPages.sent &&
        this.statusForAdvancedSearch != this.allStatusPages.uncompleted &&
        this.statusForAdvancedSearch != this.allStatusPages.underway &&
        this.statusForAdvancedSearch != this.allStatusPages.resend
      ){
        return true
      }else{
        return false
      }
    },
    showBranch(){
      if(              
        this.statusForAdvancedSearch != this.allStatusPages.saved &&
        this.statusForAdvancedSearch != this.allStatusPages.sent &&
        this.statusForAdvancedSearch != this.allStatusPages.withCompany
      ){
        return true
      }else{
        return false
      }
    },
    isFormValid() {
      if (
        !this.errors.any() &&
        (this.advancedSearchObj.code != "" ||
          this.advancedSearchObj.theDateOfDelivery != "" ||
          this.advancedSearchObj.dueDate != "" ||
          this.advancedSearchObj.sentDate != "" ||
          this.advancedSearchObj.barcode != "" ||
          this.advancedSearchObj.sender != "" ||
          this.advancedSearchObj.invoiceNumber != "" ||
          this.advancedSearchObj.receiver != "" ||
          this.advancedSearchObj.receiverNumber != "" ||
          this.advancedSearchObj.area != "" ||
          this.advancedSearchObj.govId != "" ||
          this.advancedSearchObj.branchId != "" ||
          this.advancedSearchObj.captainId != "" ||
          this.advancedSearchObj.dateOfRegistration != "" ||
          this.advancedSearchObj.collectedAmount != "")
      ) {
        return true;
      } else {
        return false;
      }
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  watch: {
    'advancedSearchObj.govName': {
      handler(newVal) {
        if(typeof(newVal) === "number"){
          this.advancedSearchObj.govId = newVal.toString()
        }   
      },
      immediate: true,
      deep:true
    },
    'advancedSearchObj.branchId': {
      handler(newVal) {
        if(typeof(newVal) === "number"){
          this.advancedSearchObj.branchId = newVal.toString()
        }   
      },
      immediate: true,
      deep:true
    },
    'advancedSearchObj.captainId': {
      handler(newVal) {
        if(typeof(newVal) === "number"){
          this.advancedSearchObj.captainId = newVal.toString()
        }   
      },
      immediate: true,
      deep:true
    },
  },
  methods: {
    ...mapActions("dataList", [
      "advancedSearch",
      "getBranches",
      "getCaptains",
      "fetchGovData",
    ]),
    openOptions(nameOfRef) {
      let el = this.$refs[nameOfRef];
      $(el).slideToggle();
    },
    resultOptions(ref1, ref2) {
      let el1 = this.$refs[ref1];
      let el2 = this.$refs[ref2];
      $(el1).css("opacity", 1);
      $(el2).slideUp();
    },
    async searchAll(type) {
      this.loading = true;
      if (type == 1) {
        if (this.search == "") {
          this.resultGovs = this.governments;
        } else {
          this.resultGovs = this.governments.filter((governments) =>
            governments.name.toLowerCase().includes(this.search.toLowerCase())
          );
        }
      } else if (type == 2) {
        let obj = {
          page: this.pageForCaptains,
          search: this.searchCaptain,
        };
        await this.getCaptains(obj);
        this.resultCaptians = this.captains
      } else if (type == 3) {
        let obj = {
          page: this.pageForBranches,
          search: this.searchBranch,
        };
        await this.getBranches(obj);
        this.resultBranchs = this.branches
      }
      this.loading = false;
    },
    initValues() {
      Object.keys(this.advancedSearchObj).forEach((key) => {
        this.advancedSearchObj[key] = "";
      });
    },
    submitData() {
      if (this.isFormValid) {
        if (this.advancedSearchTable) {
          if (this.statusForAdvancedSearch != 0) {
            this.advancedSearchObj.status = this.statusForAdvancedSearch;
          }
        }
        if (this.advancedSearchObj.dateOfRegistration != "") {
          this.advancedSearchObj.dateOfRegistration = new Date(
            this.advancedSearchObj.dateOfRegistration
          )
            .toISOString()
            .split("T")[0];
        }
        if (this.advancedSearchObj.sentDate != "") {
          this.advancedSearchObj.sentDate = new Date(
            this.advancedSearchObj.sentDate
          )
            .toISOString()
            .split("T")[0];
        }
        if (this.advancedSearchObj.theDateOfDelivery != "") {
          this.advancedSearchObj.theDateOfDelivery = new Date(
            this.advancedSearchObj.theDateOfDelivery
          )
            .toISOString()
            .split("T")[0];
        }
        if (this.advancedSearchObj.dueDate != "") {
          this.advancedSearchObj.dueDate = new Date(
            this.advancedSearchObj.dueDate
          )
            .toISOString()
            .split("T")[0];
        }
        if (this.advancedSearchObj.dateOfSenderReturn != "") {
          this.advancedSearchObj.dateOfSenderReturn = new Date(
            this.advancedSearchObj.dateOfSenderReturn
          )
            .toISOString()
            .split("T")[0];
        }
        if (this.advancedSearchObj.dateAddedtoWallet != "") {
          this.advancedSearchObj.dateAddedtoWallet = new Date(
            this.advancedSearchObj.dateAddedtoWallet
          )
            .toISOString()
            .split("T")[0];
        }
        this.$emit("closeSidebar");
        this.$router
          .push({
            path: "/ResultAdvancedSearch",
            query: { obj: this.advancedSearchObj },
          })
          .catch(() => {});
        this.initValues();
      }
    },
  },
  async created() {
    if (this.governments.length == 0) {
      await this.fetchGovData();
    }
    this.resultGovs = this.governments;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_advancedSearch.scss";
@import "@/assets/scss/vuexy/_editOrder.scss";
</style>
<style lang="scss">
.advanced-date {
  .vdp-datepicker {
    div {
      input {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3125rem;
        height: 2.4375rem;
        padding: 0 0.75rem;
        margin-bottom: 1.25rem;
        color: rgba(0, 0, 0, 0.7);
        width: 100%;
      }
      input::placeholder {
        color: rgba(0, 0, 0, 0.7) !important;
        opacity: 50%;
        font-size: 0.9rem;
        line-height: 1.3125rem;
        font-family: "cairo";
      }
    }
  }
  label {
    font-size: 0.85rem;
    margin-bottom: 2px;
    color: rgba(0, 0, 0, 0.7);
    line-height: 1.3125rem;
    padding-left: 5px;
    display: block;
  }
}
.vs-con-input {
  input {
    height: 2.4375rem;
  }
}
.input-select {
  position: relative;
  label {
    padding-left: 5px;
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.6);
  }
  input {
    cursor: pointer;
    width: 100%;
    border: 0.0625rem solid #d8d6de;
    border-radius: 0.3125rem;
    height: 2.4375rem;
    padding: 0 0.75rem;
    margin-bottom: 1.5rem;
    &::placeholder {
      color: #6e6b7b;
      opacity: 50%;
      font-size: 0.75rem;
      line-height: 1.3125rem;
    }
  }
  svg {
    position: absolute;
    top: 35px;
    right: 12px;
  }
  .select-options {
    display: none;
    max-height: 260px;
    overflow: auto;
    border: 1px solid #eee;
    padding: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(-20px);
    .inner-input {
      cursor: default;
      margin-bottom: 0px;
    }
    li {
      background: transparent;
      padding: 5px;
      width: 100%;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
.search-input {
  .con-select {
    width: 100%;
    .input-select-con {
      input {
        width: 100%;
        border: 0.0625rem solid #d8d6de;
        border-radius: 0.3125rem;
        height: 2.4375rem;
        padding: 0 0.75rem;
        margin-bottom: 1.5rem;
      }
      input::placeholder {
        color: #6e6b7b;
        opacity: 50%;
        font-size: 0.75rem;
        line-height: 1.3125rem;
        font-family: "cairo";
      }
      .vs-select--icon {
        top: 33%;
      }
    }
  }
}
.vs-select--options {
  z-index: 9999999999;
  right: 21.6px;
  input {
    font-family: "cairo";
    color: #6e6b7b;
    width: 100%;
    border: 0.0625rem solid #d8d6de;
    border-radius: 0.3125rem;
    height: 2.4375rem;
    padding: 0 0.75rem;
    margin-bottom: 0.625rem;
  }
  input::placeholder {
    color: #6e6b7b;
    opacity: 50%;
    font-size: 0.75rem;
    line-height: 1.3125rem;
    font-family: "cairo";
  }
  div {
    li {
      button {
        span {
          font-family: "cairo";
        }
      }
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
[dir] .input-select .select-options{
  transform: translateY(0);
}
</style>
