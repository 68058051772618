<template>
    <div class="hasData flex items-center justify-center bg-white px-6">
      <img src="@/assets/images/elements/has-data.png" >
      <h5>{{$t('sorryDataTable')}}</h5>
    </div>
</template>

<script>
export default {
    name: 'empty-data',
}
</script>

<style lang="scss" scoped>
.hasData{
  height: 24.5rem;
  flex-direction: column;
  h5{
    margin-top: 0.75rem;
    line-height: 2.9375rem;
    font-size: 1rem;
    color: #212121;
    font-weight: bold;
  }
}
</style>