import { mapActions } from "vuex";
export default {
  data() {
    return {
      credentials: {
        page: "order/saved",
        pageNum: null,
        itemsPerPage: 10,
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions("dataList", ["fetchDataListItems"]),
    async fetchData(data) {
      this.loading = true;
      await this.fetchDataListItems(data);
      this.loading = false;
    },
  },
};
