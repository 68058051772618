<template>
  <div id="data-list-list-view" class="data-list-container" >
    <div class="mb-4">
      <vs-alert :active="do_error_msg" color="danger" icon="new_releases" >
        <span>{{err_msg}}</span>
      </vs-alert>
    </div>
    <div v-if="loading" class="loading-pagination">
      <SpinnerColor />
    </div>
    <add-advanced-search :advancedSearchData="advancedSearchData" :isSidebarActive="addNewDataSidebar" :statusForAdvancedSearch="statusForAdvancedSearch" @closeSidebar="toggleDataSidebar" :advancedSearchTable="advancedSearchTable" />
    <edit-data v-if="addNewDataSidebarEdit" :isSidebarActive="addNewDataSidebarEdit" @closeSidebar="toggleDataSidebarEdit" :orderId="sidebarDataEdit" :pageName="pageName" />
    <vs-table ref="table" :multiple="pageName != 'last/order'" v-model="selected" noDataText="" :max-items="itemsPerPage" :data="data">

      <div slot="header" class="flex flex-wrap items-center flex-grow w-full" v-if="pageName != 'last/order'">
        <div class="flex flex-wrap-reverse justify-between items-center data-list-btn-container w-full">
          <!-- TITLE TABLE -->
          <div>
            <feather-icon icon="PackageIcon" svgClasses="h-4 w-4"></feather-icon>
            <h3 class="font-bold mb-2 ml-3" style="display: inline-block; font-size: 1.125rem;">{{ $t(titlePage) }}</h3>
          </div>
          <div class="flex items-center">
            <!-- advanced search -->
              <button v-if="titlePage != 'searchResults'" @click="openAdvancedSearch()" class="advanced-search flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.825" height="16.653" viewBox="0 0 16.825 16.653">
                  <g id="search" transform="translate(0.956 1)">
                    <circle id="Ellipse_274" data-name="Ellipse 274" cx="6" cy="6" r="6" transform="translate(0.044)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="Line_69" data-name="Line 69" x1="3.469" y1="3.469" transform="translate(10.986 10.77)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Icon_awesome-plus" data-name="Icon awesome-plus" d="M5.162,4.434H3.375V2.647a.4.4,0,0,0-.4-.4h-.4a.4.4,0,0,0-.4.4V4.434H.4a.4.4,0,0,0-.4.4v.4a.4.4,0,0,0,.4.4H2.184V7.412a.4.4,0,0,0,.4.4h.4a.4.4,0,0,0,.4-.4V5.625H5.162a.4.4,0,0,0,.4-.4v-.4A.4.4,0,0,0,5.162,4.434Z" transform="translate(3.676 0.971)" fill="#31006f"/>
                  </g>
                </svg>
                {{ $t('advancedSearch') }}
                <i>
                  <svg data-v-7f6390f4="" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left h-4 w-4"><polyline data-v-7f6390f4="" points="15 18 9 12 15 6"></polyline></svg>
                </i>
              </button>
            <!-- ACTION - DROPDOWN -->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">

              <div class="p-4 shadow-drop shadow-drop2 rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
                <span class="mr-2">{{ $t('actions') }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>
                <ul style="min-width: 11rem">
                  <vs-dropdown-item class="m-0 p-0" :disabled="!selected || (selected && !selected.length)" @click="() => printSelected('policy')">
                    <li
                      class="flex py-4 px-4 cursor-pointer profile-drop">
                      <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                      <span>
                        {{ $t('printPolicy') }} {{selected && selected.length > 1 ? `(${selected.length})` : '' }}
                      </span>
                    </li>
                  </vs-dropdown-item>

                  <vs-divider class="m-0" />

                  <vs-dropdown-item class="m-0 p-0" :disabled="!selected || (selected && !selected.length)" @click="() => printSelected('sticker')">
                    <li
                      class="flex py-4 px-4 cursor-pointer profile-drop">
                      <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                      <span>
                        {{ $t('printSticker') }} {{selected && selected.length > 1 ? `(${selected.length})` : '' }}
                      </span>
                    </li>
                  </vs-dropdown-item>
                    
                  <vs-divider class="m-0" />
                  <vs-dropdown-item class="m-0 p-0" v-if="this.pageName == 'order/sent' || this.pageName == 'order/saved' || this.pageName == 'last/order' || this.pageName == 'order/uncompleted'" @click.stop="popupActiveMethod(selected, 2)">
                    <li
                      class="flex py-4 px-4 cursor-pointer profile-drop">
                      <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                      <span>{{ $t('delete') }}</span>
                    </li>
                  </vs-dropdown-item>

                  <vs-divider class="m-0" />
                  <vs-dropdown-item class="m-0 p-0" v-if="this.pageName == 'order/saved' || this.pageName == 'last/order' || this.pageName == 'order/sent'" @click.stop="changeOrder()">
                    <li
                      class="flex py-4 px-4 cursor-pointer profile-drop">
                      <feather-icon icon="RefreshCcwIcon" svgClasses="h-4 w-4" class="mr-2" />
                      <span>{{ this.pageName == 'order/saved' || this.pageName == 'last/order' ? $t('transToSent') : "تحويل الي المحفوظة" }}</span>  
                    </li>
                  </vs-dropdown-item>
                </ul>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
        <vs-divider class="m-0 mt-4" />
      </div>

      <template slot="thead">
        <!-- :sort-key="head.key" -->
        <vs-th :key="index" v-for="(head, index) in datatableHeader" :sort-key="head.key">
          {{ $t(head.header) }}
        </vs-th>
      </template>

      <template slot-scope="{data}">
          <!-- ORDERS -->
        <tbody v-if="hasData">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :class="{'given-office': tr.order_type == 2}">
            <vs-td :key="index" v-for="(td, index) in datatableBody">
              <router-link :to="'/OrderDetails/'+tr.code" @click.native="eventClickTrackGoogleEvent">
                <p v-if="tr[td] == 'لا يوجد'">
                  <vs-chip class="product-sender" style="font-size: 0.5625rem;">لا يوجد</vs-chip>
                </p>
                <p v-else-if="td == 'code'" class="font-medium truncate code">{{ tr[td] }}</p>
                <p v-else-if="td == 'returned_inventory_status'" class="font-medium truncate" >{{ returnedInventoryStatus(tr.returned_inventory_status) }}</p>
                <p v-else-if="td == 'created_at'" class="font-medium truncate">{{ new Date(tr[td]).toLocaleDateString('en-EG', { hour: '2-digit', minute: '2-digit' }) }}</p>
                <p v-else class="font-medium truncate">{{ tr[td] }}</p>
              </router-link>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <feather-icon v-if="canEditOrder" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr.id)" />
              <feather-icon v-if="canDeleteOrder" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="popupActiveMethod(tr.id, 1)" />
            </vs-td>
          </vs-tr>
        </tbody>
        <!-- Popup Confirmation -->
        <vs-popup class="holamundo"  :title="$t('areYouDelete')" :active.sync="popupActive" @close="triggerClose" ref="popupcontent">
          <p style="padding-bottom: 1rem;">{{ $t('willDelete') }}</p>
          <vs-button style="font-family: 'Cairo', sans-serif !important;" @click.stop="deleteData()" color="danger" class="mr-3" type="filled">{{ $t('accept') }}</vs-button>
          <vs-button style="font-family: 'Cairo', sans-serif !important;" @click="popupActive=false" color="primary" type="filled">{{ $t('cancel') }}</vs-button>
        </vs-popup>
        <!-- Notfication Toast -->
        <button ref="notificationToast" :color="notficationColor" type="flat" style="display: none"
          @click="$vs.notify({
          title: notficationTitle,
          text: $t(notficationText),
          color: notficationColor,
          iconPack: 'feather',
          icon:'icon-check'})">
        </button>
      </template>
    </vs-table>
    <div v-if="hasData && pageName != 'last/order'" class="flex justify-between items-center px-6 responsive-footer">
      <!-- ITEMS PER PAGE -->
      <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
        <div style="font-family: 'Cairo', sans-serif !important; padding: 0.75rem !important; border-radius: 0.25rem !important;" class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
          <span class="mr-2">{{ currentPage }} - {{ itemsPerPage * page }} {{ $t('of') }} {{ feed_count }}</span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
        </div>
        <vs-dropdown-menu>
          <ul style="min-width: 8rem">
            <vs-dropdown-item @click="itemsPerPage=10"  class="m-0 p-0">
              <li
                class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>10</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item @click="itemsPerPage=20"  class="m-0 p-0">
              <li
                class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>20</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item @click="itemsPerPage=50"  class="m-0 p-0">
              <li
                class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>50</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item @click="itemsPerPage=100"  class="m-0 p-0">
              <li
                class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>100</span>
              </li>
            </vs-dropdown-item>
            <vs-divider class="m-0" />
            <vs-dropdown-item @click="itemsPerPage=500"  class="m-0 p-0">
              <li
                class="flex py-4 px-4 cursor-pointer profile-drop">
                <span>500</span>
              </li>
            </vs-dropdown-item>
          </ul>
        </vs-dropdown-menu>
      </vs-dropdown>
      <div>
        <!-- PAGINATION -->
        <vs-pagination
          :total="totalPage"
          v-model="page"
          :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'"
          :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'" 
        />
      </div>
    </div>
    <empty-data v-else-if="!hasData" />
  </div>
</template>

<script>
import EditData from './EditData.vue'
import EmptyData from './EmptyData.vue'
import { mapActions, mapGetters } from "vuex"
import SpinnerColor from './SpinnerColor2.vue'
import emptyTable from '@/mixins/datatableEmpty.js'
import helper from '@/mixins/helper.js'
import AddAdvancedSearch from '@/views/orders/AddAdvancedSearch.vue'
import gTag from "@/mixins/gtag.js"

export default {
  name: 'data-table-order',
  mixins: [emptyTable, helper, gTag],
  components: {
    EditData,
    SpinnerColor,
    EmptyData,
    AddAdvancedSearch
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    header: {
      type: Array,
      required: true
    },
    body: {
      type: Array,
      required: true
    },
    pageName: {
      type: String
    },
    titlePage: {
      type: String
    },
    advancedSearchData: {
      type: Array
    },
    statusForAdvancedSearch: {
      type: Number
    }
  },
  data () {
    return {
      selected: [],
      checkSelect: false,
      itemsPerPage: 10,
      isMounted: false,
      popupActive: false,
      id: '',
      page: 1,
      loading: false,
      sidebarData: {},
      sidebarDataEdit: null,
      addNewDataSidebarEdit: false,
      addNewDataSidebar: false,
      advancedSearchTable: true,
      do_error_msg: false
    }
  },
  computed: {
    ...mapGetters('dataList', ['orders', 'feed_count', 'success', 'max_order_updated_at', 'err_msg']),
    canEditOrder() {
      if (this.pageName == 'order/sent' || this.pageName == 'order/saved' || 
          this.pageName == 'last/order' || this.pageName == 'order/with-captain' || 
          this.pageName == 'order/under-way' || this.pageName == 'order/delayed' || 
          this.pageName == 'order/uncompleted') {
        return true
      }
      return false
    },
    canDeleteOrder() {
      if (this.pageName == 'order/sent' || this.pageName == 'order/saved' ||
          this.pageName == 'last/order' || this.pageName == 'order/uncompleted') {
        return true
      } else return false
    },
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    allOrders () {
      return this.data
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.allOrders.length
    },
    totalPage() {
      return Math.ceil(this.feed_count / this.itemsPerPage)
    },
    datatableHeader() {
      if (this.$acl.check('isPublicCompany')) {
        return this.header.filter((header) => header.header != 'branch' && header.header != 'captain')
      } else if (this.$acl.not.check('isPublicCompany')) {
        return this.header
      }
    },
    datatableBody() {
      if (this.$acl.check('isPublicCompany')) {
        return this.body.filter((body) => body != 'branch_name' && body != 'captain_name')
      } else if (this.$acl.not.check('isPublicCompany')) {
        return this.body
      }
    }
  },
  watch: {
    page: {
      async handler() {
        this.loading = true
        if (this.titlePage != 'searchResults') {
          let credentials = {
            page: this.pageName,
            pageNum: this.page,
            itemsPerPage: this.itemsPerPage,
            maxOrderUpdatedAt: this.max_order_updated_at
          } 
          await this.fetchDataListItems(credentials)
        } else {
          let obj = this.$route.query.obj
          obj["page"] = this.page
          obj["perPage"] = this.itemsPerPage
          await this.advancedSearch(obj)
        }
        this.loading = false
      },
      deep: true
    },
    itemsPerPage: {
      async handler() {
        this.loading = true
        if (this.titlePage != 'searchResults') {
          let credentials = {
            page: this.pageName,
            pageNum: this.page,
            itemsPerPage: this.itemsPerPage,
            maxOrderUpdatedAt: this.max_order_updated_at
          } 
          await this.fetchDataListItems(credentials)
        } else {
          let obj = this.$route.query.obj
          obj["page"] = this.page
          obj["perPage"] = this.itemsPerPage
          await this.advancedSearch(obj)
        }
        this.loading = false
      },
      deep: true
    },
    orders: {
      handler() {
        this.tableHasData(this.orders)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('dataList', ["deleteOrder", "fetchDataListItems", "changeOrderStatus", "changeOrderStatusSaved", "advancedSearch"]),
    editData (data) {
      this.googleEventTrack('الضغط على ايقون التعديل من الخارج', 'data_table_order', 'edit_order_icon')
      this.sidebarDataEdit = data
      this.toggleDataSidebarEdit(true)
    },
    async deleteData () {
      if (this.checkSelect == true) {
        let id = ''
        this.selected.forEach(element => {
          id += `${element.id}|`;
        });
        await this.deleteOrder(id)
        this.selected = []
        let credentials = {
          page: this.pageName,
          pageNum: this.page,
          itemsPerPage: this.itemsPerPage,
          maxOrderUpdatedAt: this.max_order_updated_at
        } 
        this.fetchDataListItems(credentials)
        this.popupActive = false
      } else {
          await this.deleteOrder(this.id)
          let credentials = {
            page: this.pageName,
            pageNum: this.page,
            itemsPerPage: this.itemsPerPage,
            maxOrderUpdatedAt: this.max_order_updated_at
          } 
        this.fetchDataListItems(credentials)
        this.popupActive = false
      }
      if (this.$store.state.dataList.successApi == 1) {
        this.notficationTost(true, 'deleteToast')
        this.$refs.notificationToast.click();
      } else {
        this.notficationTost(false, 'FaildDelete')
        this.$refs.notificationToast.click();
      }
    },
    async changeOrder() {
      let id = '';
      this.selected.forEach(element => {
        id += `${element.id}|`;
      });
      if (this.pageName == 'order/saved' || this.pageName == 'last/order') {
        await this.changeOrderStatus(id)
      } else {
        await this.changeOrderStatusSaved(id)
      }
      this.selected = []
      let credentials = {
        page: this.pageName,
        pageNum: this.page,
        itemsPerPage: this.itemsPerPage,
        maxOrderUpdatedAt: this.max_order_updated_at
      } 
      this.fetchDataListItems(credentials)
      if (this.$store.state.dataList.successApi == 1) {
        this.notficationTost(true, 'doneChangeStatus');
        this.$refs.notificationToast.click();
      } else {
        this.do_error_msg = true
        this.notficationTost(false, 'faildDoneChangeStatus')
        this.$refs.notificationToast.click();
      }
    },
    toggleDataSidebarEdit (val = false) {
      this.addNewDataSidebarEdit = val
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    openAdvancedSearch() {
      this.toggleDataSidebar(true);
    },
    triggerClose () {
      this.$refs.popupcontent.$el.children[1].children[1].scrollTop = 0
    },
    async popupActiveMethod (id, type) {
      this.popupActive = true;
      if (type === 2) {
        this.checkSelect = true;
      } else {
        this.googleEventTrack('الضغط على اكشن الحذف من الخارج', 'data_table_order', 'delete_order_icon')
        this.checkSelect = false;
        this.id = id;
      }
    },
    printSelected(type = 'policy') {
      if (!this.selected.length) {
        return;
      }

      let code = "";
      this.selected.forEach((item) => {
        code += `${item.code}|`;
      });

      this.$router.push({
        path: (type === 'policy' ? '/printOrder/' : '/printSticker/'),
        query: { selected: code },
      });
      this.selected = [];
    },
    addDataSidebar () {
      this.toggleDataSidebar(true)
    },
    returnedInventoryStatus (status) {
      switch (status) {
        case 7:
        return "مدفوع";
        case 8:
        return "علي الراسل";
        case 9:
        return "الغاء";
        case 17:
        return "مدفوع";
        case 18:
        return "علي الراسل";
        case 19:
        return "الغاء";
        case 24:
        return "جزئي";
        case 26:
        return "استبدال";
        case 27:
        return "استرجاع";
      }
    },
    eventClickTrackGoogleEvent() {
      this.googleEventTrack('الضغط على كود الطرد للدخول الى تفاصيل الطرد', 'data_table_order', 'go_to_order_details')
    }
  },
  mounted () {
    this.isMounted = true
  },
  created() {
    this.tableHasData(this.orders)
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/_dataTable.scss';
</style>
<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';
a{
  color: #626262 !important;
}
.given-office{
  background-color: #FF9F431A;
  a{
    color: #ff9f43 !important;
  }
}
.advanced-search{
  height: 38px;
  background-color: #FFF;
  box-shadow: 0px 4px 24px #0000000D;
  color: $mainColor1;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  padding: 0px 12px;
  border: none;
  margin-right: 24px;
  cursor: pointer;
  border-radius: 4px;
  svg{
    margin-right: 8px;
  }
  i{
    height: 14px;
    svg{
      margin-left: 8px;
      margin-right: 0;
    }
  }
}
</style>